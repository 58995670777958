import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import {
  Input,
  Text,
  Button,
  Link,
  InputGroup,
  InputRightElement,
  FormControl,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import * as styles from "./sign-in-page.module.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { GET_USER } from "../useAuth";
import { gql, useMutation } from "@apollo/client";

const LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: { login: $login, password: $password }) {
      status
    }
  }
`;

const SignInPage: FunctionComponent = () => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [logIn, { loading, error }] = useMutation(LOG_IN, {
    refetchQueries: [
      {
        query: GET_USER,
      },
    ],
  });

  const [message2, setMessage] = useState("");
  const [error2, setError] = useState(null);

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    if (!isValidEmail(event.target.value)) {
      setError("Incomplete email");
    } else {
      setError(null);
    }

    setMessage(event.target.value);
  };

  const errorMessage = error?.message || "";
  const isEmailValid =
    !errorMessage.includes("empty_email") &&
    !errorMessage.includes("empty_username") &&
    !errorMessage.includes("invalid_email") &&
    !errorMessage.includes("invalid_username");
  const isPasswordValid =
    !errorMessage.includes("empty_password") &&
    !errorMessage.includes("incorrect_password");

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data: any = new FormData(event.currentTarget);

    const values = Object.fromEntries(data);

    const email = values.login;
    const password = values.password;

    logIn({
      variables: {
        login: email,
        password,
      },
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <div className={styles.signInPageDiv}>
      <div className={styles.registerSectionDiv}>
        <div className={styles.registerContentDiv}>
          <div className={styles.contactFormDiv}>
            <form
              method="post"
              onSubmit={handleSubmit}
              className={styles.contactFormInputsDiv}>
              <div className={styles.formTitleDiv}>
                <div className={styles.displayDiv}>Sign In</div>
              </div>
              <div className={styles.inputRowDiv}>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.emailDiv}>Email</div>
                  </div>
                  <Input
                    id="log-in-email"
                    className={styles.input}
                    variant="outline"
                    textColor="#1a202c"
                    borderColor="#e2e8f0"
                    focusBorderColor="#3182ce"
                    placeholder="johndoe@email.com"
                    type="email"
                    name="login"
                    autoComplete="username"
                    required
                    value={message2}
                    onChange={handleChange}
                  />
                  {error && <Text style={{ color: "red" }}>{error2}</Text>}
                  {!isEmailValid ? (
                    <Text color="red" className="error-message">
                      Invalid email. Please try again.
                    </Text>
                  ) : null}
                </div>
              </div>
              <div className={styles.inputRowDiv}>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.emailDiv}>Password</div>
                  </div>
                  <InputGroup size="md">
                    <Input
                      className={styles.input}
                      variant="outline"
                      textColor="#1a202c"
                      borderColor="#e2e8f0"
                      focusBorderColor="#3182ce"
                      type={show ? "text" : "password"}
                      id="log-in-password"
                      name="password"
                      autoComplete="current-password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button size="sm" onClick={handleClick}>
                        {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {!isPasswordValid ? (
                    <Text color="red" className="error-message">
                      Invalid password. Please try again.
                    </Text>
                  ) : null}

                  <div className={styles.inputLabelDiv2}>
                    <div className={styles.ifYouAreNotRegisteredGo}>
                      <span>{`If you are not registered, go to `}</span>
                      <b>
                        {" "}
                        <a href="/register" className={styles.registerPage}>
                          Register page
                        </a>
                      </b>
                      .
                      <span>
                        {" "}
                        If you forgot your password you can reset it on a{" "}
                        <b>
                          <a
                            href="/password-reset"
                            className={styles.registerPage}>
                            {" "}
                            Forgot password page
                          </a>
                        </b>
                        .
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                className={styles.button}
                variant="solid"
                colorScheme="gcsblue"
                rightIcon={<CheckIcon />}
                type="submit">
                {loading ? "Signing in..." : "Sign In"}
              </Button>
            </form>
          </div>
        </div>
        <img
          className={styles.contactDetailsIcon}
          alt=""
          src="/contactdetails@2x.png"
        />
      </div>
    </div>
  );
};

export default SignInPage;
